import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import logoImage from '../assets/conorque2.avif';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAuth, updateRenovar } from "../features/auth/authSlice";
import '../css/Login.css';
import '../css/General.css';
import DotSpinner from "../components/DotSpinner";

const theme = createTheme();

export default function Login() {
  const [nombre, setNombre] = useState("");
  const [contra, setContra] = useState("");
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const Swal = require('sweetalert2');
  const dispatch = useDispatch();

  const IngresarLogin = (event) => {
    event.preventDefault();
    if (nombre === "") {
      console.log("No esta ingresando su usuario");
      return;
    } else if (contra === "") {
      console.log("No esta ingresando su contraseña");
      return;
    }

    setLoading(true); 

    const ingreso = {
      "userName": nombre,
      "password": contra
    };
    const tokenId = localStorage.getItem("token");
    fetch("https://190.57.143.126:52100/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenId}`,
      },
      body: JSON.stringify(ingreso)
    })
      .then(respuesta => respuesta.json())
      .then(datos => procesamiento(datos))
      .catch(error => {
        console.error(error);
        setLoading(false); 
      });

    const procesamiento = (datos) => {
      setLoading(false);  
      if (datos.token) {
        localStorage.setItem("token", datos.token);
        localStorage.setItem("expiracion", datos.expiracion);
        dispatch(updateRenovar({
          token: datos.token,
          expiracion: datos.expiracion,
          datos_Usuario: datos.datos_Usuario,
        }));
        const tokenId = localStorage.getItem("token");
        fetch("https://190.57.143.126:52100/user/obtainPermission", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tokenId}`,
          },
        })
          .then(respuesta => respuesta.json())
          .then(datos => recuperardatos(datos))
          .catch(error => console.error(error));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "ERROR",
          text: "Ingresa correctamente tu usuario y contraseña",
          showConfirmButton: false,
          timer: 2500
        });
      }
    }
  }

  const recuperardatos = (datos) => {
    dispatch(updateAuth({
      datos_Usuario: datos.datos_Usuario,
      permissions: datos.permissions,
    }));
    if (datos.datos_Usuario.ROL === "employees") {
      navigate("/mesatrabajo");
    } else if (datos.datos_Usuario.ROL === "supplier") {
      navigate("/home");
    }
  }

  const handleNombre = (e) => {
    setNombre(e.target.value);
  }

  const handleContra = (e) => {
    setContra(e.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='container-login'>
      {loading && <DotSpinner />} 
        <div className='transparent-container'>
          <br />
          <div className="logo-container">
            <img src={logoImage} alt="Logo" className="logo" />
          </div>
          <br />
          
            <form className="login-form">
              <div className='InputContainer'>
                <input
                  className="styled-input"
                  required
                  type="text"
                  placeholder="Usuario"
                  name="nombre"
                  value={nombre}
                  onChange={handleNombre}
                />
              </div>
              <div className='InputContainer'>
                <input
                  className="styled-input"
                  required
                  placeholder="Contraseña"
                  name="Contraseña"
                  type="password"
                  value={contra}
                  onChange={handleContra}
                />
              </div>
              <div className='ButtonContainer'>
                <button className="styled-button" type="submit" onClick={IngresarLogin}>Ingresar</button>
              </div>
              <div className='InputContainer'>
                <label htmlFor="password" className="InputLabel">Síguenos en nuestras redes</label>
              </div>
              <Grid container spacing={2} justifyContent="center" className="container-web">
                <Stack direction="row" spacing={2}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#1877F2"
                    height='2rem'
                    onClick={() => window.open("https://www.facebook.com/p/Distribuidora-Mayorista-Conorque-100083085674592/", "_blank")}
                  >
                    <path
                      d="M22.675 0h-21.35C.597 0 0 .597 0 1.326v21.348C0 23.403.597 24 1.326 24h11.495v-9.294H9.692v-3.622h3.129V8.413c0-3.1 1.893-4.788 4.66-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.917.001c-1.503 0-1.794.715-1.794 1.763v2.31h3.587l-.467 3.621h-3.12V24h6.116c.729 0 1.326-.597 1.326-1.326V1.326C24 .597 23.403 0 22.675 0z" />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#FF0000"
                    height='2rem'
                    onClick={() => window.open("https://youtu.be/https://www.youtube.com/@megatiendadelsur7489", "_blank")}
                  >
                    <path d="M23.498 6.186c-.272-1.018-1.07-1.814-2.09-2.086C19.53 3.453 12 3.453 12 3.453s-7.53 0-9.407.647C1.573 4.372.775 5.168.502 6.186.075 7.967 0 11.991 0 11.991s.075 4.024.502 5.805c.273 1.018 1.071 1.814 2.091 2.086C4.47 20.53 12 20.53 12 20.53s7.53 0 9.407-.647c1.02-.273 1.818-1.068 2.09-2.086.428-1.781.503-5.805.503-5.805s-.075-4.024-.502-5.805zM9.606 15.206v-6.43l6.325 3.215-6.325 3.215z" />
                  </svg>
                </Stack>
              </Grid>
            </form>
        
        </div>
      </div>
    </ThemeProvider>
  );
}
