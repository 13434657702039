import Grid from "@mui/material/Grid";
import LogoFinal from '../assets/izquierda1.avif';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MyCard from "../components/card-home";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validacion } from "../utils/apiUtils";
import Container from "../components/Container";

import '../css/Home.css';

export default function HomeView() {
    const navigate = useNavigate();
    const Swal = require('sweetalert2');
    const CardCode = useSelector((state) => state.auth.datos_Usuario.CARDCODE);
    const [enviadas, setEnviadas] = useState(0);
    const [pendientes, setPendientes] = useState(0);
    const [canceladas, setCanceladas] = useState(0);
    const obtenerDatos = async () => {
        const validado = await validacion();
        if (validado === 1) {
            try {
                const tokenId = localStorage.getItem("token");
                const urls = [
                    `https://190.57.143.126:52100/purchaseorder/countordersdelivered/${CardCode}`,
                    `https://190.57.143.126:52100/purchaseorder/countpendingorders/${CardCode}`,
                    `https://190.57.143.126:52100/purchaseorder/countcanceledorders/${CardCode}`
                ];

                const respuestas = await Promise.all(
                    urls.map(url =>
                        fetch(url, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${tokenId}`
                            }
                        }).then(respuesta => respuesta.json())
                    )
                );

                const [enviadas, pendientes, canceladas] = respuestas;
                setEnviadas(enviadas);
                setPendientes(pendientes);
                setCanceladas(canceladas);
            } catch (error) {
                console.error(error);
            }
        } else {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "TIEMPO EXCEDIDO",
                text: 'Vuelve a ingresar a la APP',
                showConfirmButton: false,
                timer: 2200
            });
            navigate('/');
        }
    };

    const soporteSistemas = () => {
        navigate("/sistemas")
    }

    const videoTutorial = () => {
        window.open("https://www.youtube.com/playlist?list=PLjG7vu746Oy6wIF24Fn7rSyRJMsT4U3Ck", "_blank");
    }

    const manualPortal = () => {
        const pdfUrl = '/MANUAL.pdf'; 
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'ManualPortal.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    

    useEffect(() => {
        obtenerDatos();
    }, []);
    return (
        <>
            <Container className="container-home" fluid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6.5}>
                        <div className="container-imageHome">
                            <img className="image-home" src={LogoFinal} alt="" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5.5}>
                        <div className="container-rightHome">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component="div" className="title-home" style={{ textAlign: "center" }}>
                                        Bienvenidos a la APP Provedores
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component="div" className="textoInicio" style={{ textAlign: "justify" }}>
                                        Plataforma para el manejo y control de ordenes de pedidos. A través de la app podras consultar tus ordenes, en que estado se encuentran, entre otras funciones que buscan agilizar los procesos.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                    <Button variant="outlined" className="video"  onClick={manualPortal}>
                                           Manual Portal
                                        </Button>
                                    <Button variant="outlined" className="video"  onClick={videoTutorial}>
                                           Videos Tutoriales
                                        </Button>
                                        
                                        <Button variant="contained" className="button-disystem" onClick={soporteSistemas}>
                                            Soporte Técnico 
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="cards-Home">
                                        <MyCard entregadas={enviadas} pendientes={pendientes} canceladas={canceladas} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}