import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Icon } from '../assets/x.svg';
import { Stack } from '@mui/material';
import '../css/Modal.css';

const Modal = ({
  isOpen,
  onClose,
  size = '',
  className = '',
  title = '',
  children = null
}) => {
  const modalClasses = classNames('modal', { 'show': isOpen }, className);
  const backdropClasses = classNames('modal-backdrop', { 'show': isOpen });

  const getSizeClass = () => {
    switch (size) {
      case 'xl':
        return 'modal-xl';
      case 'lg':
        return 'modal-lg';
      case 'md':
        return 'modal-md';
      case 'sm':
        return 'modal-sm';
      default:
        return '';
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <div className={modalClasses} tabIndex="-1" role="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
      <div className={`modal-dialog ${getSizeClass()}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} marginBottom={2} spacing={2}>
              <h5 className="modal-title">{title}</h5>
              <Icon onClick={onClose} />
            </Stack>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
      <div className={backdropClasses}></div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default Modal;
