import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import LogoFinal from '../assets/conorque.avif';
import '../css/Navbar.css';

const Navbar = ({ children }) => {
  const [mostrar, setMostrar] = useState(false);
  const permissions = useSelector((state) => state.auth.permissions);
  const usuario = useSelector((state) => state.auth.datos_Usuario.CARDNAME);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setMostrar(!mostrar);
  };
  

  const modalAvatar = () => {
    navigate("/configuracion");
    setMostrar(false);
  };

  const modalAvatar1 = () => {
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("expiracion");
    setMostrar(false);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={LogoFinal} height={50} alt="Logo" />
        </div>
        <div className="navbar-links desktop">
          {permissions.filter(item => item.permiso && item.menu).map((perm) => (
            <Link 
              key={perm.nombre} 
              to={perm.ruta} 
              className={`navbar-link ${location.pathname === perm.ruta ? 'active' : ''}`} 
              onClick={() => setMostrar(false)}
            >
              {perm.nombre}
            </Link>
          ))}
          <div className="navbar-avatar" onClick={toggleMenu}>
            {usuario && usuario.slice(0, 2)}
          </div>
          {mostrar && (
            <div className="navbar-dropdown">
              <div className="navbar-dropdown-item" onClick={modalAvatar}>Configuraciones</div>
              <div className="navbar-dropdown-item" onClick={modalAvatar1}>Salir</div>
            </div>
          )}
        </div>
        <div className="navbar-menu mobile" onClick={toggleMenu}>
          &#9776;
        </div>
      </nav>
      {mostrar && (
        <div className="navbar-mobile-menu">
          {permissions.filter(item => item.permiso && item.menu).map((perm) => (
            <Link 
              key={perm.nombre} 
              to={perm.ruta} 
              className={`navbar-link-mobile ${location.pathname === perm.ruta ? 'active' : ''}`} 
              onClick={() => setMostrar(false)}
            >
              {perm.nombre}
            </Link>
          ))}
          <div className="navbar-dropdown-item-mobile" onClick={modalAvatar}>Configuraciones</div>
          <div className="navbar-dropdown-item-mobile" onClick={modalAvatar1}>Salir</div>
        </div>
      )}
      <div className="page-content">{children}</div>
    </div>
  );
};

export default Navbar;
