import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { recuperarUsuario } from './utils/apiUtils';
import { updateAuth } from './features/auth/authSlice';
import { useSelector, useDispatch } from "react-redux";
import Login from './views/login';
import PrivateRoute from './protegidas';
import HomeView from './views/home';
import Navbar from './components/Navbar';
import NotFound from './views/notfound';
import DotSpinner from './components/DotSpinner';
import Employer from './views/dashboard';
import EmployessMegaView from './views/employesMega';

const OrdersView = React.lazy(() => import('./views/orders'));
const PendingView = React.lazy(() => import('./views/pending'));
const RegView = React.lazy(() => import('./views/users'));
const DeliveredView = React.lazy(() => import('./views/delivered'));
const ContactSystem = React.lazy(() => import('./views/system'));
const DeniedView = React.lazy(() => import('./views/denied'));
const SettingsView = React.lazy(() => import('./views/setting'));
const SuppliersView = React.lazy(() => import('./views/supplier'));
const OrderSupplier = React.lazy(() => import('./views/orderSupplier'));
const AutorizarView = React.lazy(() => import('./views/autorizar'));
const RecensionView = React.lazy(() => import('./views/Recension'));
const EmployeesView = React.lazy(() => import('./views/employees'));

function App() {

  const CardCode = useSelector((state) => state.auth.datos_Usuario.CARDCODE);
  const tokenId = localStorage.getItem("token");
  const dispatch = useDispatch();
  const recuperacion = (datos) => {
    dispatch(updateAuth(
      {
        datos_Usuario: datos.datos_Usuario,
        permissions: datos.permissions,
      }
    ))
  };

  (async () => {
    if (tokenId !== null && CardCode === "") {
      const datos = await recuperarUsuario();
      recuperacion(datos);
    }
  })();

  return (
    <>
      <BrowserRouter >
        <Suspense fallback={<DotSpinner />}>
        
          <Routes>
            <Route path='/' element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path='/home' element={<Navbar><HomeView /></Navbar>} />
              <Route path='/ordenes' element={<Navbar><OrdersView /></Navbar>} />
              <Route path='/enprogreso' element={<Navbar><RecensionView /></Navbar>} />
              <Route path='/pendientes' element={<Navbar><PendingView /></Navbar>} />
              <Route path='/entregadas' element={<Navbar><DeliveredView /></Navbar>} />
              <Route path='/ordencompra' element={<Navbar><OrderSupplier /></Navbar>} />
              <Route path='/mesatrabajo' element={<Navbar><Employer /></Navbar>} />
              <Route path='/mesatrabajo/autorizacion' element={<Navbar><AutorizarView /></Navbar>} />
              <Route path='/controlentrada' element={<Navbar><EmployessMegaView /></Navbar>} />
              <Route path='/controlentrada/analisis' element={<Navbar><EmployessMegaView /></Navbar>} />
              <Route path='/registro' element={<Navbar><RegView /></Navbar>} />
              <Route path='/registro/empleado' element={<Navbar><EmployeesView /></Navbar>} />
              <Route path='/registro/proveedor' element={<Navbar><SuppliersView /></Navbar>} />
              <Route path='/registro/cliente' element={<Navbar><NotFound /></Navbar>} />
            </Route>
            <Route path='/configuracion' element={<Navbar><SettingsView /></Navbar>} />
            <Route path='*' element={<NotFound />} />
            <Route path='/denegado' element={<DeniedView />} />
            <Route path='/sistemas' element={<ContactSystem />} />
          </Routes>
        </Suspense>    
      </BrowserRouter>
    </>
  );
}

export default App;
