import React, { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Notificaciones = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [notificaciones, setNotificaciones] = useState([]);
  const dialogRef = useRef(null);
  const matchesMobile = useMediaQuery('(max-width:768px)');
  const SlpCode = useSelector((state) => state.auth.datos_Usuario?.SLPCODE ?? "");

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const autorizar = async (datos_notificacion) => {
    navigate('/mesatrabajo/autorizacion');
    sessionStorage.setItem('datosOrden', JSON.stringify(datos_notificacion.ordenCompraId));
    const tokenId = localStorage.getItem("token");
    const datos = {
      estado: "lei"
    };

    try {
      const response = await fetch(`https://190.57.143.126:52100/purchaseorder/ordernotificationsqlserver/${datos_notificacion.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + tokenId,
        },
        body: JSON.stringify(datos)
      });

      if (!response.ok) {
        throw new Error(`Error al autorizar: ${response.status} - ${response.statusText}`);
      }
      const responseData = await response.json();
      console.log("Respuesta exitosa:", responseData);
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };


  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);


  const fetchNotificaciones = async () => {
    const tokenId = localStorage.getItem("token");
    fetch(`https://190.57.143.126:52100/purchaseorder/ordernotificationsqlserver/${SlpCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenId}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setNotificaciones(data);
      }
      )
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchNotificaciones();
    const interval = setInterval(fetchNotificaciones, 1800000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button onClick={handleClickOpen} style={styles.button}>
        <span style={styles.badge}>{notificaciones.length}</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" height='1.5rem'>
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
        </svg>
      </button>
      {open && (
        <div ref={dialogRef} style={{ ...styles.dialog, ...(matchesMobile ? styles.dialogMobile : {}) }}>
          <div style={styles.dialogTitle}>Ordenes Pendientes</div>
          <div style={styles.dialogContent}>
            {notificaciones.map((notificacion, index) => (
              <div
                key={index}
                style={styles.notification}
                onClick={() => autorizar(notificacion)}
              >
                {notificacion.texto}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    position: 'relative',
    padding: '0',
  },
  badge: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    background: '#39a934',
    color: 'white',
    borderRadius: '50%',
    padding: '5px 10px',
    fontSize: '12px',
  },
  dialog: {
    position: 'absolute',
    top: '35px',
    width: '300px',
    background: 'white',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: 1000,
  },
  dialogMobile: {
    width: '200px',
    right: '10px',
  },
  dialogTitle: {
    padding: '10px 15px',
    borderBottom: '1px solid #ddd',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black',
  },
  dialogContent: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '10px 15px',
  },
  notification: {
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
    color: 'black',
  },
};

export default Notificaciones;
