import { Autocomplete, Grid, Stack, TablePagination, TextField, Tooltip } from "@mui/material";
import Container from "../components/Container";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Notificaciones from "../components/Notificaciones";
import { validacion } from "../utils/apiUtils";
import { useSelector } from "react-redux";
import '../css/Dashboard.css';
import '../css/Tabla.css';

export default function Employer() {
    const navigate = useNavigate();
    const SlpCode = useSelector((state) => state.auth.datos_Usuario?.SLPCODE ?? "");
    const SlpName = useSelector((state) => state.auth.datos_Usuario?.SLPNAME ?? "");
    const Swal = require('sweetalert2');
    const [data, setData] = useState([]);
    const [codigoo, setCodigoo] = useState(0);
    const [datosSucursal, setDatosSucursal] = useState([]);
    const [sucursal, setSucursal] = useState("");
    const [datosProveedores, setDatosProveedores] = useState([]);
    const [proveedor, setProveedor] = useState("");
    const [cantItems, setCantItems] = useState(0);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const [inicio, setInicio] = useState(formatDate(today));
    const [fin, setFin] = useState(formatDate(today));
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);
    const [searchParams] = useSearchParams();
    const urlProveedor = searchParams.get('proveedor');
    const urlSucursal = searchParams.get('sucursal');
    
    const handleErrorSis = (error) => {
        console.error("Error al realizar la solicitud:", error);
        Swal.fire({
            position: "center",
            icon: "warning",
            title: "Cargando",
            text: "Espera unos segundos mientras arreglamos este problema.",
            footer: '<a href="/sistemas">Comunicarse con Soporte</a>',
            showConfirmButton: false,
            timer: 2200
        });
    };

    const claseEstado = (idEstado) => {
        let sColor = 'blanco';
        switch (idEstado) {
            case "PARA REVISION":
                sColor = 'azul';
                break;
            case "APROBADO":
                sColor = 'verde';
                break;
            case "CANCELADO":
                sColor = 'rojo';
                break;
            default:
                break;
        }
        return sColor;
    };

    const handleError = () => {
        Swal.fire({
            position: "center",
            icon: "error",
            title: "TIEMPO EXCEDIDO",
            text: 'Vuelve a ingresar a la APP',
            showConfirmButton: false,
            timer: 2200
        });
        navigate('/');
        localStorage.removeItem("token");
        localStorage.removeItem("expiracion");
    };

    const handleOrdenes = () => {
        Swal.fire({
            position: "center",
            icon: "warning",
            title: "ORDENES INEXISTENTES",
            text: "No se encontro ordenes con esos parámetro",
            showConfirmButton: false,
            timer: 2000,
        })
    };

    const autorizar = (datos_notificaciones) => {
        sessionStorage.setItem('datosOrden', JSON.stringify(datos_notificaciones.id));
        setTimeout(function () {
            navigate('/mesatrabajo/autorizacion');
        }, 500);
    };

    const visualizar = (datos_notificaciones) => {
        sessionStorage.setItem('datosOrden', JSON.stringify(datos_notificaciones.id));
        setTimeout(function () {
            navigate('/mesatrabajo/autorizacion');
        }, 500);
    };

    const handleInicio = (e) => {
        const selectedDate = new Date(e.target.value + 'T00:00:00');
        setInicio(formatDate(selectedDate));
    };

    const handleFin = (e) => {
        const selectedDate = new Date(e.target.value + 'T00:00:00');
        setFin(formatDate(selectedDate));
    };

    const handleCodigo = (e) => {
        const docNum = e.target.value;
        if (!isNaN(docNum)) {
            setCodigoo(docNum);
        } else {
            setCodigoo(1);
        }
    };

    const getSucursales = async () => {
        const tokenId = localStorage.getItem("token");
        fetch("https://190.57.143.126:52100/warehouse/ObtenerSucursales", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenId}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data)) {
                    const sucursales = data.map(sucursal => ({
                        whsCode: sucursal.whsCode,
                        whsName: sucursal.whsName || '',
                    }));
                    setDatosSucursal(sucursales);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const getProveedores = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const tokenId = localStorage.getItem("token");
            fetch(`https://190.57.143.126:52100/supplier/${SlpCode}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenId}`,
                },
            })
                .then(response => response.json())
                .then(datosP => {
                    if (datosP && Array.isArray(datosP)) {
                        const proveedores = datosP.map(proveedor => ({
                            supCode: proveedor.cardCode,
                            supName: proveedor.cardName || '',
                        }));
                        setDatosProveedores(proveedores);
                        const datos = datosP.length
                        console.log("datos", datos)
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            handleError();
        }
    };

    const handleProveedorChange = (event, newValue) => {
        setProveedor(newValue ? newValue.supCode : "");
    };

    const handleSucursal = (event) => {
        const nombreSucursal = event.target.value;
        const sucursalSeleccionada = datosSucursal.find(suc => suc.whsName === nombreSucursal);
        setSucursal(sucursalSeleccionada ? sucursalSeleccionada.whsCode : "");
    };


    const handleChangePage = async (event, newPage) => {
        const validado = await validacion();
        if (validado === 1) {
            setPage(newPage);
        } else {
            handleError();
        }
    };

    const getData = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const tokenId = localStorage.getItem("token");

            let fechInicio = inicio;
            let fechFin = fin;

            const proveedorActual = proveedor;
            const sucursalActual = sucursal;
            const codigooActual = codigoo;

            const proveedorSearchParams = searchParams.get('proveedor');
            const sucursalSearchParams = searchParams.get('sucursal');
            const codigooSearchParams = searchParams.get('codigoo');

            const proveedorEnviar = proveedorSearchParams || proveedorActual;
            const sucursalEnviar = sucursalSearchParams || sucursalActual;
            const codigooEnviar = codigooSearchParams || codigooActual || 0;
            const urlInicio = searchParams.get('fechaDesde');
            const urlFin = searchParams.get('fechaHasta');

            if (urlInicio && urlInicio !== formatDate(today)) {
                setInicio(urlInicio);
                fechInicio = urlInicio;
            } else {
                setInicio(formatDate(today));
            }

            if (urlFin && urlFin !== formatDate(today)) {
                setFin(urlFin);
                fechFin = urlFin;
            } else {
                setFin(formatDate(today));
            }

            fetch(`https://190.57.143.126:52100/purchaseorder/ordersqlserver?pagina=${page + 1}&recordsPorPagina=10&fechaDesde=${fechInicio}&fechaHasta=${fechFin}&codigoProveedor=${proveedorEnviar}&codigoAlmacen=${sucursalEnviar}&codigoOrden=${codigooEnviar}&codigoAsesor=${SlpCode}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + tokenId,
                },
            })
                .then(response => {
                    const totalRegistros = response.headers.get('Cantidadtotalregistros');
                    const transformar = parseInt(totalRegistros)
                    setCantItems(transformar)
                    return response.json();
                })
                .then(datos => {
                    pasoSiguiente(datos);
                })
                .catch((error) => {
                    handleErrorSis(error);
                });
        } else {
            handleError();
        }
    };


    const pasoSiguiente = (info) => {
        setData(info)
        if (info.length !== 0) {
            return (
                setData(info)
            );
        } else {
            handleOrdenes()
        }
    };

    const filtrarReportes = async () => {
        const validado = await validacion();
        if (validado === 1) {
            const newSearchParams = new URLSearchParams();

            if (proveedor) {
                newSearchParams.append("proveedor", proveedor);
            }
            if (sucursal) {
                newSearchParams.append("sucursal", sucursal);
            }
            if (codigoo) {
                newSearchParams.append("codigoo", codigoo);
            }
            if (inicio) {
                newSearchParams.append("fechaDesde", inicio);
            }
            if (fin) {
                newSearchParams.append("fechaHasta", fin);
            }
            setPage(0);
            navigate({
                pathname: "/mesatrabajo",
                search: newSearchParams.toString()
            });
        } else {
            handleError();
        }
    };

    const reiniciarDatos = async () => {
        const validado = await validacion();
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        const today = new Date();
        if (validado === 1) {
            navigate("/mesatrabajo");
            getData();
            setCodigoo('');
            setSucursal('');
            setProveedor('');
            setInicio(formatDate(today));
            setFin(formatDate(today));
            setPage(0);
        } else {
            handleError();
        }
    };

    useEffect(() => {
        getSucursales();
        getProveedores();
    }, []);

    useEffect(() => {
        getData();
    }, [page, searchParams]);

    return (
        <>
            <Container className="container_dashboard" fluid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2.5}>
                        <div className="carta-notificaciones">
                            <Stack direction="row" alignItems={"center"} justifyContent={"center"} spacing={2}>
                                <p>{SlpName}</p>
                                <Notificaciones />
                            </Stack>
                        </div>
                        <div>
                            <div className="custom-textfield">
                                <Autocomplete
                                    options={datosProveedores}
                                    getOptionLabel={(option) => option.supName}
                                    value={datosProveedores.find(prov => prov.supCode === proveedor | prov.supCode === urlProveedor) || null}
                                    onChange={handleProveedorChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Seleccione un proveedor"
                                        />
                                    )}
                                    disableClearable
                                />
                            </div>
                            <select
                                className="select-dashboard"
                                value={datosSucursal.find(suc => (suc.whsCode === sucursal || suc.whsCode === urlSucursal))?.whsName || ''}
                                onChange={handleSucursal}
                            >
                                <option value="">Seleccione una sucursal</option>
                                {datosSucursal.map((suc) => (
                                    <option key={suc.whsCode} value={suc.whsName}>
                                        {suc.whsName}
                                    </option>
                                ))}
                            </select>
                            <input
                                className="modal-input"
                                id="combo-box-demo"
                                name="proveIdentificadoredor"
                                type="text"
                                placeholder="Código del Pedido"
                                onChange={handleCodigo}
                                maxLength={10}
                            />
                            <label className="input-label">Desde: </label>
                            <input
                                type="date"
                                value={inicio}
                                name="Desde"
                                onChange={handleInicio}
                                className="modal-input"
                            />
                            <label className="input-label">Hasta: </label>
                            <input
                                type="date"
                                value={fin}
                                name="Hasta"
                                onChange={handleFin}
                                className="modal-input"
                            />
                        </div>
                        <button variant="contained" className="boton-gestion" onClick={filtrarReportes} >
                            Filtrar
                        </button>
                        <button variant="contained" className="boton-gestion" onClick={reiniciarDatos}>
                            Limpiar Campos
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9.5}>
                        <div className="Scroll">
                            <table className='table table-ligh table-hover'>
                                <thead>
                                    <tr><th style={{ textAlign: "center" }}>#</th>
                                        <th style={{ textAlign: "center" }}>PROVEEDOR</th>
                                        <th style={{ textAlign: "center" }}>SUCURSAL</th>
                                        <th style={{ textAlign: "center" }}>CÓDIGO PEDIDO</th>
                                        <th style={{ textAlign: "center" }}>ESTADO</th>
                                        <th style={{ textAlign: "center" }}>FECHA PEDIDO</th>
                                        <th style={{ textAlign: "center" }}>FECHA ENTREGA</th>
                                        <th style={{ textAlign: "center" }}>ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        const currentIndex = i + 1 + (page * rowsPerPage);
                                        const isDisabledVis = item.estado === "PARA REVISION";
                                        const isDisabledEdit = item.estado === "APROBADO" || item.estado === "NO APROBADA";

                                        return (
                                            <tr key={currentIndex}>
                                                <td style={{ textAlign: "center" }}>{currentIndex}</td>
                                                <td style={{ textAlign: "center" }}>{item.nombreProveedor}</td>
                                                <td style={{ textAlign: "center" }}>{item.nombreAlmacen}</td>
                                                <td style={{ textAlign: "center" }}>{item.id}</td>
                                                <td className={`center ${claseEstado(item.estado)}`}>{item.estado}</td>
                                                <td style={{ textAlign: "center" }}>{(new Date(item.fechaDocumento).toLocaleDateString('es', { day: "numeric", month: "short", year: "numeric" }))}</td>
                                                <td style={{ textAlign: "center" }}>{(new Date(item.fechaEntrega).toLocaleDateString('es', { day: "numeric", month: "short", year: "numeric" }))}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Stack spacing={2} direction={"row"} justifyContent={"center"}>
                                                        <Tooltip title="Editar">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="#151635"
                                                                height='1.5rem'
                                                                onClick={() => { if (!isDisabledEdit) autorizar(item); }}
                                                                style={{ cursor: isDisabledEdit ? 'not-allowed' : 'pointer', opacity: isDisabledEdit ? 0.3 : 1 }}>
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                            </svg>
                                                        </Tooltip>
                                                        <Tooltip title="Visualizar">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                onClick={() => { if (!isDisabledVis) visualizar(item); }}
                                                                fill="none" viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="#1dbb3a"
                                                                height='1.5rem'
                                                                style={{ cursor: isDisabledVis ? 'not-allowed' : 'pointer', opacity: isDisabledVis ? 0.3 : 1 }}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                            </svg>
                                                        </Tooltip>
                                                    </Stack>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={cantItems}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[]}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}